import React, { useState } from 'react';
import { Dropdown, Button } from '../../tailwind';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import i18next from 'i18next';
import { Icon } from '../../components/icon/icon';
import { useTranslation } from 'react-i18next';

export function LangSelector() {
  const { t } = useTranslation();
  const languages = Object.keys(i18next.services.resourceStore.data).map((language) => language.toUpperCase());
  const [selectedIndex, setSelectedIndex] = useState(
    languages.findIndex((language) => language === i18next.language.toUpperCase())
  );
  const onSelect = ({ value }, index) => {
    i18next.changeLanguage(value.toLowerCase());
    setSelectedIndex(index);
  };
  return (
    <Dropdown
      items={languages.map((value) => ({ name: value, value }))}
      selectedIndex={selectedIndex}
      onSelect={(item, index) => onSelect(item, index)}
    >
      <Button
        className='hidden md:inline-flex'
        gradient={false}
        rounded={true}
        small={true}
        icon={solid('earth-americas')}
        component={({ children, ...props }) => <div {...props}>{children}</div>}
      >
        {languages[selectedIndex]}
      </Button>
      <div className='md:hidden w-full p-2 sm:px-3 flex flex-row hover:bg-gray-300 hover:border-gray-400 border-l-4'>
        <Icon className='mt-1 text-xl text-gray-600 '>{solid('earth-americas')}</Icon>
        <div className='text-gray-600 ml-3 mt-0.5'>{`${t('language')} (${languages[selectedIndex]})`}</div>
      </div>
    </Dropdown>
  );
}
