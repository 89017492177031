import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '../../components/icon/icon';

export function Tabs({ items = [] }) {
  const [active, setActive] = useState(0);
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  useEffect(() => {
    const routes = items.map(({ path }) => path && path);
    routes.includes(pathname) ? setActive(routes.findIndex((path) => path === pathname)) : setActive(null);
  }, [pathname, items]);
  return (
    <div className='md:flex w-full justify-between'>
      {items.map(({ name, path, icon }, index) => (
        <Link
          key={index}
          to={path}
          className={`inline-flex mr-2 items-center px-4 py-2 text-md border-b-2 font-medium hover:cursor-pointer hover:text-primary ${
            active === index ? 'border-primary text-primary' : 'border-transparent text-gray-600'
          }`}
          onClick={() => setActive(index)}
        >
          <Icon className='mr-2'>{icon}</Icon>
          <div>{t(name)}</div>
        </Link>
      ))}
    </div>
  );
}
