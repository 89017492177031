import React from 'react';
import { Disclosure } from '@headlessui/react';
import Logo from './../../images/logo.svg';
import { Tabs, Button, Container } from '../../tailwind';
import { Icon } from '../../components/icon/icon';
import { Link, useLocation } from 'react-router-dom';
import { LangSelector } from './lang-selector';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';

const links = [
  { name: 'services.title', path: '/', icon: solid('list') },
  { name: 'pricing.title', path: '/pricing', icon: solid('money-check') },
  { name: 'company.title', path: '/company', icon: solid('building') },
  { name: 'contact.title', path: '/contact', icon: solid('address-book') }
];

export function Header() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <Disclosure as='nav' className='bg-white sticky top-0 shadow z-50'>
      {({ open }) => (
        <>
          <Container>
            <div className='flex justify-between my-2'>
              <Link to='/'>
                <img className='max-h-12 md:max-h-16 w-full' src={Logo} alt='Scripteal' />
              </Link>
              <div className='flex items-center md:hidden absolute right-0'>
                <div className='-ml-2 mr-2 flex items-center'>
                  <Disclosure.Button className='inline-flex items-center justify-center p-2 mt-0.5 rounded-md text-gray-400'>
                    <Icon className='block h-6 w-6'>{open ? solid('xmark') : solid('bars')}</Icon>
                  </Disclosure.Button>
                </div>
              </div>
              <div className='flex items-center'>
                <div className='hidden md:flex-shrink-0 md:grid grid-flow-col md:items-center md:gap-3'>
                  <Button rounded={true} small={true} icon={solid('file-import')} to='/contact'>
                    {t('becomeClient')}
                  </Button>
                  <LangSelector />
                </div>
              </div>
            </div>
            <div className='hidden md:flex items-center w-full'>
              <Tabs items={links} />
            </div>
          </Container>
          <Disclosure.Panel className='md:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3 sm:px-3'>
              {links.map(({ name, path, icon }, index) => (
                <Link key={index} to={path}>
                  <Disclosure.Button
                    className={`${
                      pathname === path
                        ? 'border-primary text-primary'
                        : 'text-gray-600 hover:bg-gray-200 hover:border-gray-400'
                    } w-full flex flex-row px-3 border-l-4 py-2 text-base font-medium text-gray-600`}
                  >
                    <Icon className='mr-4 text-xl'>{icon}</Icon>
                    <div>{t(name)}</div>
                  </Disclosure.Button>
                </Link>
              ))}
            </div>
            <div className='border-t border-gray-700 pt-2 pb-3'>
              <div className='px-2 grid grid-flow-row gap-2'>
                <LangSelector />
                <Button icon={solid('file-import')} to='/proposal'>
                  {t('proposal.button')}
                </Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
