import React, { useEffect, useRef, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Icon } from '../../../icon/icon';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../form';

export function Input({ type = 'text', id, title, onChange, validation, value = '', icon, required = false }) {
  const { registerField } = useForm();

  const element = useRef();
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const change = (event) => {
    onChange && onChange(event);
    element.current.setCustomValidity('');
    setError(false);
  };

  const onInvalid = () => {
    setError(true);

    if (element.current.validity.valueMissing) {
      element.current.setCustomValidity(t('form.required'));
    } else if (element.current.validity.patternMismatch) {
      element.current.setCustomValidity(t('form.error.format'));
    } else {
      element.current.setCustomValidity('');
    }
  };

  useEffect(() => {
    registerField(id, element);
    element.current.value = value;
  }, [id, element]);

  return (
    <div>
      <label className='block text-sm font-medium text-gray-700' htmlFor={id}>
        {t(title)}
        {required ? `*` : ''}
      </label>
      <div className='relative mt-1 rounded-md shadow-sm'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <Icon className='text-xl text-gray-400'>{icon}</Icon>
        </div>
        <input
          id={id}
          ref={element}
          type={type}
          onChange={change}
          onInvalid={onInvalid}
          pattern={validation}
          required={required}
          placeholder={t(title)}
          className={`${
            error ? 'border-red-500 focus:border-red-500' : 'focus:border-primary focus:ring-primary'
          } block w-full rounded-md border-gray-300 pl-10  sm:text-sm ${icon ? 'pl-10' : 'pl-6'}`}
        />
        {error && (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <Icon className='h-5 w-5 text-red-500'>{solid('circle-exclamation')}</Icon>
          </div>
        )}
      </div>
    </div>
  );
}
