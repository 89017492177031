export const translation = {
  language: 'Langue',
  becomeClient: 'Devenir client',
  footer: {
    companyNumber: 'Numéro d’entreprise',
    itaaNumber: 'Numéro ITAA',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email'
  },
  services: {
    title: 'Services',
    welcome: 'Simplifiez votre comptabilité et gestion financière grâce à <b>Alex’pertize</b>',
    description:
      'Chez <b>Alex’pertize</b>, nous croyons que la comptabilité ne devrait jamais être un fardeau. Que vous soyez un independant cherchant à optimiser votre déclaration d’impôt ou une entreprise en quête de solutions comptables complètes, nous mettons notre expertise à votre service pour vous aider à atteindre vos objectifs financiers.',
    ourServices: 'Nos services',
    ourApproach: 'Des solutions adaptées à vos besoins et à votre budget',
    independent: {
      title: 'Pour les independant',
      description:
        'Nous prenons en charge vos obligations fiscales, y compris la déclaration <b>IPP</b> (Impôt des Personnes Physiques), en vous offrant un accompagnement personnalisé pour maximiser vos avantages fiscaux et éviter les erreurs coûteuses.'
    },
    company: {
      title: 'Pour les entreprises',
      description: 'Nous proposons une gestion complète de votre comptabilité, incluant :',
      items: [
        'La tenue et la mise à jour régulière de votre comptabilité.',
        'La déclaration trimestrielle et le listing annuel <b>TVA</b>.',
        'La rédaction et le dépôt des comptes annuels dans le <b>BNB</b>.',
        'La confirmation et mise à jour du registre <b>UBO</b>.',
        'Les déclarations fiscales, comme l’<b>ISOC</b> (impôt des sociétés).'
      ]
    },
    advice: {
      title: 'Conseils et accompagnement stratégique',
      description:
        'Vous développez une nouvelle activité ou avez besoin de conseils pour une décision importante ? Notre équipe vous guide avec des solutions sur mesure et des recommandations pratiques, y compris pour la préparation aux contrôles fiscaux.'
    }
  },
  pricing: {
    title: 'Tarifs',
    subtitle: 'Des solutions adaptées à vos besoins et à votre budget.',
    description:
      'Chez <b>Alex’pertize</b>, nous comprenons que chaque client a des besoins différents, c’est pourquoi nous avons conçu une grille tarifaire claire et flexible, qui garantit un excellent rapport qualité-prix tout en restant accessible.',
    personalAccounting: {
      title: 'Comptabilité personnelle',
      description:
        'Comptabilité personnelle, conseil et consulting, des solutions adaptées à vos besoins et à votre budget.',
      items: [
        'Déclaration <b>IPP</b> (Impôt des Personnes Physiques) à partir de <b>100 € HTVA par session</b>.',
        'Conseils fiscaux personnalisés pour optimiser vos impôts.',
        'Accompagnement stratégique pour le lancement de nouvelles activités.',
        'Tarifs personnalisés sur devis, selon la nature et la complexité de vos projets.'
      ]
    },
    companyAccounting: {
      title: 'Comptabilité d’entreprise',
      description:
        'Nous proposons une gestion complète de votre comptabilité sous un forfait trimestriel standard à partir <b>750 € HTVA</b>.',
      items: [
        'La tenue complète de votre comptabilité.',
        'Les déclarations TVA trimestrielles et le listing annuel <b>TVA</b>.',
        'La rédaction et le dépôt des comptes annuels dans le <b>BNB</b>.',
        'La confirmation annuelle du registre <b>UBO</b>.',
        'Les déclarations <b>ISOC</b> (impôt des sociétés).'
      ]
    },
    specificServices: {
      title: 'Services spécifiques',
      description: 'Des services spécifiques et prestations à la carte',
      items: [
        'Mise à jour du registre UBO, <b>100 € HTVA</b> par modification.',
        'Préparation et assistance en cas de contrôle fiscal, <b>120 € HTVA par heure</b>.',
        'Préparation de dossiers pour demandes de crédit ou audits financiers, <b>100 € HTVA par heure</b>.'
      ]
    },
    model: {
      title: 'Un modèle tarifaire transparent',
      description:
        'Nos tarifs sont adaptés à la charge de travail, la complexité de votre dossier et vos besoins spécifiques. Les forfaits incluent toutes les prestations standard, tandis que les services hors forfait sont facturés selon un tarif horaire clair. <b>Aucun coût caché</b>, toutes les conditions sont spécifiées dès le début de notre collaboration.'
    }
  },
  company: {
    title: 'Entreprise',
    subtitle: 'Votre partenaire de confiance en comptabilité et gestion financière',
    description:
      'Basé à <b>Waterloo</b>, <b>Alex’pertize</b> a été fondé par <b>Didier Alexandre</b>, un <b>expert-comptable</b> certifié avec une passion pour l’accompagnement des independants et des entreprises dans leurs défis financiers. Notre mission est d’aider nos clients à se concentrer sur ce qui compte vraiment, leur croissance et leur réussite.',
    mission: {
      title: 'Notre mission',
      description:
        'Chez <b>Alex’pertize</b>, notre objectif est clair, rendre la comptabilité accessible, compréhensible et utile pour tous. Nous vous offrons un accompagnement humain et transparent, basé sur une expertise reconnue et une compréhension approfondie des réglementations locales.',
      text: 'Nous croyons fermement qu’une comptabilité bien maîtrisée est un levier essentiel pour la réussite de vos projets. C’est pourquoi <b>Alex’pertize</b> s’efforce de fournir des solutions sur mesure, adaptées à vos besoins spécifiques, tout en favorisant la simplicité et la clarté dans chaque interaction. Ensemble, construisons une gestion financière solide et sereine.'
    },
    vision: {
      title: 'Notre vision',
      description:
        'Nous aspirons à devenir le cabinet comptable de référence en <b>Belgique</b>, en alliant innovation, proximité et excellence professionnelle. Nous croyons que chaque client mérite un service sur mesure, adapté à ses ambitions et à ses contraintes.',
      text: 'Chez <b>Alex’pertize</b>, nous souhaitons repousser les limites de la comptabilité traditionnelle en intégrant les meilleures pratiques et technologies du secteur. Notre objectif est de transformer vos défis financiers en opportunités, tout en restant fidèles à nos valeurs de transparence, d’écoute et d’engagement. Ensemble, façonnons un avenir où votre croissance est au cœur de nos priorités.'
    },
    values: {
      title: 'Nos valeurs fondamentales:',
      items: [
        '<b>Transparence</b> : Des services clairs et des tarifs sans surprise.',
        '<b>Engagement</b> : Une écoute attentive et des solutions adaptées à chaque situation.',
        '<b>Proactivité</b> : Nous anticipons vos besoins pour éviter les mauvaises surprises et optimiser vos performances.',
        '<b>Expertise</b> : Des compétences reconnues et régulièrement mises à jour.'
      ]
    },
    becomeClient: {
      title: 'Rejoignez-nous',
      description:
        'Faites le choix d’une comptabilité qui travaille avec vous. Chez <b>Alex’pertize</b>, nous mettons notre expertise et notre engagement à votre service pour simplifier votre quotidien et booster votre réussite. Contactez-nous dès aujourd’hui et découvrez comment nous pouvons transformer votre gestion comptable en un véritable atout stratégique. Votre succès commence ici !'
    }
  },
  contact: {
    title: 'Contact',
    subtitle: 'Parlons de vos projets !',
    description:
      'Vous avez des questions ou souhaitez en savoir plus sur nos services ? <b>Alex’pertize</b> est là pour vous accompagner.',
    howToContact: {
      title: 'Comment nous contacter ?',
      items: [
        'Par email : Envoyez-nous vos demandes à <b>didier@alexpertize.be</b>, et nous vous répondrons sous 48 heures ouvrables.',
        'Par téléphone : Appelez-nous directement au <b>+32 487 108 560</b>. Notre équipe est à votre écoute pour fixer un rendez-vous ou discuter de vos besoins.'
      ]
    },
    whyContact: {
      title: 'Pourquoi nous contacter ?',
      items: [
        'Nous sommes toujours disponibles pour vous conseiller et répondre à vos interrogations.',
        'Obtenez un devis gratuit et personnalisé en fonction de votre situation.',
        'Profitez d’une première consultation sans engagement pour découvrir comment <b>Alex’pertize</b> peut vous simplifier la vie.'
      ]
    },
    name: 'Nom',
    email: 'Email',
    phone: 'Téléphone',
    subject: 'Sujet',
    message: 'Message'
  },
  form: {
    submit: 'Envoyer',
    required: 'Ce champs est requis.',
    error: {
      sent: 'Une erreur s’est produite, veuillez réessayer plus tard.',
      format: 'Veuillez remplir le champs correctement.'
    }
  }
};
