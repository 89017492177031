import React, { useState } from 'react';
import { Input } from '../../components/form/types/input/input';
import { Textarea } from '../../components/form/types/textarea/textarea';
import { Form } from '../../components/form/form';
import { Button, Container, Card, Trans } from '../../tailwind';
import { useTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export function Contact() {
  const { t } = useTranslation();

  const [isFilled, setFilled] = useState(false);

  const onChange = (fields) => {
    const isFilled = Object.values(fields)
      .filter(({ current: { required } }) => required)
      .every(({ current: { value } }) => value.trim() !== '');

    setFilled(isFilled);
  };

  const onSubmit = (data, reset) => {
    console.log(data);
    reset();
  };

  return (
    <Container>
      <div className='grid gap-x-5 md:grid-cols-2'>
        <Card title='contact.howToContact.title' className='mt-5'>
          <ul className='list-disc list-outside ml-4'>
            {t('contact.howToContact.items', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <Trans i18nKey={item} />
              </li>
            ))}
          </ul>
        </Card>
        <Card title='contact.whyContact.title' className='mt-5'>
          <ul className='list-disc list-outside ml-4'>
            {t('contact.whyContact.items', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <Trans i18nKey={item} />
              </li>
            ))}
          </ul>
        </Card>
      </div>
      <Card title='contact.subtitle' className='mt-5'>
        <Trans i18nKey='contact.description' />
        <Form onSubmit={onSubmit} onChange={onChange}>
          <div className='grid gap-5 md:grid-cols-2 mb-5'>
            <Input type='text' title='contact.name' id='name' icon={solid('id-card')} required={true} />
            <Input
              type='email'
              title='contact.email'
              id='email'
              icon={solid('envelope')}
              required={true}
              validation='^[^ ]*@[^ ]*\.[^ ]*$'
            />
            <Input
              type='tel'
              title='contact.phone'
              id='phone'
              icon={solid('phone')}
              validation='^[+]?[0-9\/\s\-\.]+$'
            />
            <Input type='text' title='contact.subject' id='subject' icon={solid('bars')} required={true} />
          </div>
          <Textarea type='text' title='contact.message' id='message' icon={solid('message')} required={true} />
          <div className='flex justify-end mt-5'>
            <Button icon={solid('paper-plane')} type='submit' disabled={!isFilled}>
              {t('form.submit')}
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
}
