import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { Icon } from '../../components/icon/icon';

export function Dropdown({ items = [], onSelect, children, selectedIndex }) {
  const { t } = useTranslation();
  return (
    <div className='inline-block text-left w-full'>
      <Menu>
        {({ open }) => (
          <>
            <span className='rounded-md shadow-sm'>
              <Menu.Button className='flex w-full'>{children}</Menu.Button>
            </span>
            <Transition
              show={open}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                className={`absolute right-0 w-full md:w-52 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-50`}
              >
                <div className='py-1'>
                  {items.map((item, index) => (
                    <Menu.Item key={index}>
                      <div onClick={() => onSelect(item, index)} className='group w-full'>
                        <span
                          className={`${
                            selectedIndex === index
                              ? 'bg-gray-200 text-primary'
                              : 'text-gray-500 hover:text-gray-900 group-hover:cursor-pointer'
                          } flex items-center px-4 py-2 text-sm`}
                        >
                          {item.icon && (
                            <Icon
                              className={`${
                                selectedIndex === index ? 'text-primary' : ''
                              } mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-hover:cursor-pointer`}
                            >
                              {item.icon}
                            </Icon>
                          )}
                          <span className={`group-hover:cursor-pointer ${item.icon ? 'ml-4' : 'ml-0'}`}>
                            {t(item.name)}
                          </span>
                        </span>
                      </div>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
