import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from './../../images/logo-footer.svg';
import { Icon } from '../../components/icon/icon';
import { Container } from '../../tailwind';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const links = [
  {
    name: 'footer.companyNumber',
    text: 'BE 0672 886 327',
    path: 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?lang=fr&nummer=0672.886.327',
    icon: solid('building')
  },
  {
    name: 'footer.itaaNumber',
    text: '53.250.875',
    path: 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?lang=fr&nummer=0672.886.327',
    icon: solid('file-invoice')
  },
  {
    name: 'footer.phoneNumber',
    text: '+32 487 108 560',
    path: 'tel:032487108560',
    icon: solid('phone')
  },
  {
    name: 'footer.email',
    text: 'didier@alexpertize.be',
    path: 'mailto:didier@alexpertize.be',
    icon: solid('envelope')
  }
];

export function Footer() {
  const { t } = useTranslation();
  return (
    <footer className='bg-gradient-button py-6 text-gray-50 text-sm'>
      <Container className='md:flex md:justify-between grid grid-rows-3 max-[330px]:grid-rows-6 grid-flow-col'>
        {links.map(({ name, text, icon, path }, index) => (
          <a
            className='grid grid-rows-3 grid-flow-col auto-cols-max mr-2'
            key={index}
            href={path}
            target='_blank'
            rel='noreferrer'
          >
            <div className='w-5 row-span-3'>
              <Icon>{icon}</Icon>
            </div>
            <div className='font-medium'>{t(name)}</div>
            <div>{text}</div>
          </a>
        ))}
        <div></div>
        <Link to='/home' className='flex justify-end'>
          <div className='grid place-content-center'>
            <img src={logo} className='w-40' alt='Scripteal' />
            <div className='text-xs text-center mt-2'>© 2024, All rights reserved</div>
          </div>
        </Link>
      </Container>
    </footer>
  );
}
