import React from 'react';
import { Button, Card, Container, Trans } from '../../tailwind';
import { useTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import didier from '../../images/didier.jpg';

export function Company() {
  const { t } = useTranslation();
  return (
    <Container>
      <Card title='company.subtitle' className='mt-5'>
        <div className='sm:flex flex-wrap'>
          <div className='flex-1 max-md:mr-5 max-lg:mr-10 mr-20'>
            <Trans i18nKey='company.description' />
            <h5 className='text-lg text-primary my-4'>
              <Trans i18nKey='company.values.title' />
            </h5>
            <ul className='list-disc list-outside ml-4'>
              {t('company.values.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>
                  <Trans i18nKey={item} />
                </li>
              ))}
            </ul>
          </div>
          <div className='w-auto my-auto grid place-content-center max-sm:mt-10'>
            <img src={didier} alt='Alexpertize' className='w-full max-w-[200px] h-auto rounded-full' />
          </div>
        </div>
      </Card>
      <div className='grid gap-x-5 md:grid-cols-2'>
        <Card title='company.mission.title' className='mt-5'>
          <Trans i18nKey='company.mission.description' />
          <Trans i18nKey='company.mission.text' />
        </Card>
        <Card title='company.vision.title' className='mt-5'>
          <Trans i18nKey='company.vision.description' />
          <Trans i18nKey='company.vision.text' />
        </Card>
      </div>
      <Card title='company.becomeClient.title' className='mt-5'>
        <>
          <p className='mb-10'>
            <Trans i18nKey='company.becomeClient.description' />
          </p>
          <Button rounded={true} icon={solid('file-import')} to='/contact'>
            <Trans i18nKey='becomeClient' />
          </Button>
        </>
      </Card>
    </Container>
  );
}
