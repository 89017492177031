import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as en from './locales/en';
import * as fr from './locales/fr';
import * as nl from './locales/nl';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie']
    },
    resources: { en, fr, nl },
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

export default i18n;
