import React from 'react';
import { Container, Banner, Card, Button, Trans } from '../../tailwind';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import services from '../../images/services.png';
import { useTranslation } from 'react-i18next';

export const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner className={`py-5`}>
        <Container>
          <div className='sm:flex flex-wrap'>
            <div className='flex-1 grid grid-gap-4 max-md:mr-5 max-lg:mr-10 mr-20'>
              <div className='max-md:text-xl max-xl:text-[1.6rem] text-3xl leading-normal leading-10'>
                <Trans i18nKey='services.welcome' />
              </div>
              <div className='flex-1 flex items-end'>
                <span className='mt-4 md:text-lg tracking-wider'>
                  <Trans i18nKey='services.description' />
                </span>
              </div>
            </div>
            <div className='w-auto my-auto grid place-content-center max-sm:mt-10'>
              <img src={services} alt='Alexpertize' className='w-full max-w-[200px] h-auto rounded-full' />
            </div>
          </div>
        </Container>
      </Banner>

      <Container>
        <Card title='services.ourServices' className='mt-5 text-gray-600'>
          <>
            <Trans i18nKey='services.ourApproach' />

            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-5'>
              <Card
                title='services.independent.title'
                className='text-gray-600'
                hasShadow={false}
                background='bg-orange-50'
                icon={solid('user-tie')}
              >
                <Trans i18nKey='services.independent.description' />
              </Card>
              <Card
                title='services.company.title'
                className='text-gray-600'
                hasShadow={false}
                background='bg-orange-50'
                icon={solid('building')}
              >
                <Trans i18nKey='services.company.description' />
                <ul className='list-disc list-outside ml-4'>
                  {t('services.company.items', { returnObjects: true }).map((item, index) => (
                    <li key={index}>
                      <Trans i18nKey={item} />
                    </li>
                  ))}
                </ul>
              </Card>
              <Card
                title='services.advice.title'
                className='text-gray-600'
                hasShadow={false}
                background='bg-orange-50'
                icon={solid('lightbulb')}
              >
                <Trans i18nKey='services.advice.description' />
              </Card>
            </div>

            <div className='mt-10'>
              <Button rounded={true} icon={solid('file-import')} to='/contact'>
                <Trans i18nKey='becomeClient' />
              </Button>
            </div>
          </>
        </Card>
      </Container>
    </>
  );
};
