import React from 'react';
import { Banner, Card, Container, Button, Trans } from '../../tailwind';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import pricing1 from '../../images/pricing-1.png';
import pricing2 from '../../images/pricing-2.png';
import pricing3 from '../../images/pricing-3.png';
import { Icon } from '../../components/icon/icon';

const images = [pricing1, pricing2, pricing3];
const icons = [solid('user-tie'), solid('building'), solid('lightbulb')];

const PricingItem = ({ name, index }) => {
  const { t } = useTranslation();

  const { title, description, items } = t(`pricing.${name}`, { returnObjects: true });

  return (
    <Card className='col-span-4' hasShadow={false} background='bg-orange-50'>
      <>
        <img src={images[index]} alt={title} className='rounded-md mb-5' />
        <div>
          <h5 className='text-lg text-primary font-bold mb-4'>
            <Icon className='mr-2'>{icons[index]}</Icon>
            <Trans i18nKey={title} />
          </h5>
          {description && (
            <p className='text-gray-500 mb-4'>
              <Trans i18nKey={description} />
            </p>
          )}
          {items && (
            <ul className='list-disc list-outside ml-4'>
              {items.map((item, index) => (
                <li key={index}>
                  <Trans i18nKey={item} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </>
    </Card>
  );
};

export function Pricing() {
  return (
    <Container>
      <Card className='mt-5 -pt-5'>
        <Banner className='grid place-content-center -m-5.1'>
          <div className='m-5 mb-52 text-center'>
            <h5 className='text-2xl md:text-3xl text-gray-50 font-bold'>
              <Trans i18nKey='pricing.subtitle' />
            </h5>
            <p className='mt-4 text-gray-200 mb-5 md:mb-0 md:mt-2'>
              <Trans i18nKey='pricing.description' />
            </p>
          </div>
        </Banner>
        <>
          <div className='grid gap-14 grid-cols-4 items-stretch md:grid-cols-8 lg:grid-cols-12 -mb-52 relative -top-52'>
            {['personalAccounting', 'companyAccounting', 'specificServices'].map((item, index) => (
              <PricingItem key={index} name={item} index={index} />
            ))}
          </div>
          <h3 className='text-xl my-5 text-primary font-medium leading-6'>
            <Trans i18nKey='pricing.model.title' />
          </h3>
          <p>
            <Trans i18nKey='pricing.model.description' />
          </p>
          <div className='mt-10'>
            <Button rounded={true} icon={solid('file-import')} to='/contact'>
              <Trans i18nKey='becomeClient' />
            </Button>
          </div>
        </>
      </Card>
    </Container>
  );
}
