import React, { createContext, useContext, useRef } from 'react';

const FormContext = createContext();

export const Form = ({ children, onSubmit, onChange }) => {
  const form = useRef();
  const fields = useRef({});

  const change = () => {
    onChange && fields.current && onChange(fields.current);
  };

  const registerField = (id, reference) => {
    fields.current[id] = reference;
    change();
  };

  const reset = () => {
    form.current.reset();
    change();
  };

  const submit = (event) => {
    event.preventDefault();

    const formData = Object.entries(fields.current).reduce(
      (
        data,
        [
          id,
          {
            current: { value }
          }
        ]
      ) => {
        data[id] = value;
        return data;
      },
      {}
    );

    onSubmit && onSubmit(formData, reset);
  };

  return (
    <FormContext.Provider value={{ registerField }}>
      <form ref={form} onSubmit={submit} onChange={change}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export const useForm = () => {
  return useContext(FormContext);
};
