import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './header/header';
import { Footer } from './footer/footer';
import { BreakpointIndicator } from '../tailwind';
import { ScrollRestoration } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const isDev = process.env.NODE_ENV === 'development';

export const Layout = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <ScrollRestoration />
      <Header />
      <div className='flex-1 grow bg-gradient-body pb-5'>
        <Outlet key={i18n.language} />
      </div>
      <Footer />
      {isDev && <BreakpointIndicator />}
    </>
  );
};
