import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../components/icon/icon';

export function Card({ children, title, subtitle, className, hasShadow = true, background = 'bg-white', icon }) {
  const { t } = useTranslation();
  return (
    <div
      className={`overflow-hidden ${background} ${
        hasShadow ? 'shadow' : ''
      } rounded-md md:rounded-lg pb-2 ${className}`}
    >
      {title && (
        <div className='px-4 pt-5'>
          <h3 className='text-lg text-primary font-medium leading-6'>
            {icon && <Icon className='mr-2'>{icon}</Icon>} {t(title)}
          </h3>
          {subtitle && <p className='mt-1 max-w-2xl text-sm text-gray-500'>{t(subtitle)}</p>}
        </div>
      )}
      {Children.toArray(children).map((item, index) => (
        <div key={index} className={index > 0 ? 'border-t border-gray-200 text-gray-600 p-5' : 'text-gray-600 p-5'}>
          {item}
        </div>
      ))}
    </div>
  );
}
