import React from 'react';
import { Icon } from '../../components/icon/icon';
import { Link } from 'react-router-dom';

function HtmlButton({ type = 'button', children, ...props }) {
  return (
    <button {...props} type={type}>
      {children}
    </button>
  );
}

export function Button({
  icon,
  children,
  disabled = false,
  gradient = true,
  rounded = false,
  small = false,
  className = '',
  to,
  component,
  ...props
}) {
  const Component = component || (to && !disabled ? Link : HtmlButton);

  const classes = [
    `rounded-${rounded ? 'full' : 'md'}`,
    small ? 'px-3 py-1' : 'px-6 py-3',
    `text-sm font-semibold`,
    `flex-inline items-center`,
    `disabled:opacity-50`,
    gradient ? 'bg-gradient-button text-white' : 'text-gray-600 ring-gray-300 ring-1',
    !disabled ? `focus:outline-none focus:ring-inset focus:ring-2 focus:${!gradient ? 'ring-primary' : ''}` : '',
    !disabled ? (gradient ? `hover:ring-2 hover:ring-purple-primary` : 'hover:text-primary hover:bg-gray-50') : '',
    className
  ];

  return (
    <Component className={classes.join(' ')} disabled={disabled} to={to} {...props}>
      {icon && <Icon className='mr-2'>{icon}</Icon>}
      {children}
    </Component>
  );
}
