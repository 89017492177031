import React from 'react';
import { Layout } from './layout';
import { Services, Pricing, Company, Contact } from '../sections';

import { createBrowserRouter, Navigate } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      ['/', Services],
      ['pricing', Pricing],
      ['company', Company],
      ['contact', Contact]
    ].map(([path, Component]) => ({ path, element: <Component /> }))
  },
  {
    path: '*',
    element: <Navigate to='/' replace />
  }
]);
